import React from 'react'
import Header from '../Header/Header.jsx';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter';

const Hero = () => {

  const transtion = { type: 'spring', duration: 3 }
  const mobile=window.innerWidth<=786 ? true : false;
  return (
    <div className="hero" id='home'>
      <div className="blur blur-h"></div>
      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "178px":'238px' }}
            whileInView={{ left: '8px' }}
            transition={{ ...transtion, type: 'tween' }}
          ></motion.div>
          <span>The Best fitness Club In The Town</span>
        </div>
        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>ideal body</span>
          </div>
          <div>
            <span> in here we will help you to shape and buid your ideal body and live up your life to fullest</span>
          </div>
        </div>

        {/* figure */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter preFix="+" end={140} start={100} delay='2'/>
            </span>
            <span>Experts Cotches</span>
          </div>
          <div>
            <span>
           <NumberCounter preFix="+" end={978} start={800} delay='2'/>
            </span>
            <span>Members Joind</span>
          </div>
          <div>
            <span>
            <NumberCounter preFix="+" end={50} start={0} delay='2'/>
            </span>
            <span>fitness programs</span>
          </div>
        </div>

        {/* hero button */}

        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      {/* right-side */}

      <div className="right-h">
        <button className='btn'>Join Now</button>
        <motion.div
        initial={{ right: '-1rem' }}
            whileInView={{ right: '4rem' }}
          transition={transtion }
          className='heart-rate'>
        <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>

        </motion.div>


        {/* hero section */}
        <img src={hero_image} alt="" className='hero-image' />
        <motion.img
        initial={{right:'11rem'}}
        whileInView={{right:'20rem'}}
        transition={transtion}
         src={hero_image_back} alt="" className='hero-image-back'>
        </motion.img>

        {/* calories */}
        <motion.div
        initial={{right:'37rem'}}
        whileInView={{right:'28rem'}}
        transition={transtion}
        className='calories'>
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burn</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
       
      </div>
    </div>
  )
}

export default Hero;