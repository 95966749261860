import React from 'react';
import './Header.css';
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png';
import { useState } from 'react';
import {Link} from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpen, setMenueOpen] = useState(false)
  return (
    <div className="header">
      <img src={Logo} alt="" className='logo' />
      {(menuOpen === false && mobile === true)
        ? (
          <div
            style={{
              backgroundColor: "var(--appColor)",
              padding: "0.5rem",
              borderRadius: "5px"
            }}
            onClick={()=> setMenueOpen(true)}
               > 
          <img src={Bars} alt=""
            style={{
              width: '1.5rem',
              height: '1.5rem'
            }} />
          </div>)
        : (
          <ul className='header-menu'>
            <li>
            <Link
             onClick={()=>setMenueOpen(false)}
             activeClass="active"
             to='home'
             span={true}
             smooth={true}
            > Home</Link>
             </li>
            <li>
            <Link
             onClick={()=>setMenueOpen(false)}
             to='programs'
             span={true}
             smooth={true}
            >programs</Link>
             </li>
            <li>
            <Link
             onClick={()=>setMenueOpen(false)}
             to='Whyus'
             span={true}
             smooth={true}
            >Why us</Link>
             </li>
            <li>
            <Link
             onClick={()=>setMenueOpen(false)}
             to='Plans'
             span={true}
             smooth={true}
            > Plans</Link>
             </li>
            <li>
            <Link
             onClick={()=>setMenueOpen(false)}
             to='Testimonials'
             span={true}
             smooth={true}
            >Testimonials</Link>
             </li>
          </ul>
        )}

    </div>
  )
}

export default Header;