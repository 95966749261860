import React from 'react'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Linkdin from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
import "./Footer.css"

const Footer = () => {
  return (

    <div className="footer_container">
      <hr />
      <div className="footer">
        <div className="social-links">

          <img src={Github} alt="" />
          <img src={Instagram} alt="" />
          <img src={Linkdin} alt="" />
        </div>
        <div className="logo-f">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="blur blur-footer1"></div>
      <div className="blur blur-footer2"></div>
    </div>
  )
}

export default Footer