
import Hero from './components/Hero/Hero';
import Programs from './components/programs/programs';
import Reason from './components/Reason/Reason';
import Plans  from './components/plans/plans';
import Testimoniols from './components/testimoniols/testimoniols';
import Join from './components/join/join';
import Footer from './components/Footer/Footer';
import './App.css';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Programs/>
          <Reason/>
          <Plans/>
          <Testimoniols/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
